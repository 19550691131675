const AR = {
  // A
  activated: 'تفعيل الطالب',
  action: 'الإعدادات',
  activation_year: 'تتفعيل السنه',
  add_announcement: 'إضافة إعلان',
  add_fees: 'إضافه رسوم',
  add_new_lecture: 'اضافة حصة جديده',
  add_new_level: 'إضافة صف جديد',
  add_new_role: 'إضافه صلاحية جديدة',
  add_new_section: ' إضافة مرحله جديد',
  add_new_studnet: 'إضافه طالب جديد',
  add_new_subject: 'إضافة مادة جديدة',
  add_new_teacher: 'إضافه مدرس جديد',
  add_new_user: 'إضافه مستخدم جديد',
  add_new: 'إضافه جديد',
  add_new_fee: 'إضافة رسوم جديدة',
  add_other: 'إضافة أخرى',
  add_premium: 'إضافة قسط',
  added_successfully: 'تم الاضافة بنجاح',
  additional_premiums: 'الرسوم الإضافية',
  address: 'العنوان',
  administrators: 'الإداريين',
  admission_number: 'رقم القيد',
  all_options: 'كل الخيارات',
  all: 'الكل',
  already_exists: 'موجود مسبقاَ',
  amount_paid: 'المبلغ المدفوع',
  amount_required: 'المبلغ المطلوب',
  amount: 'المبلغ',
  announcement: 'الإعلان',
  apply_filter: 'تصفية',
  arabic: 'عربي',
  attendance: 'الحضور',
  assigning_teachers_to_classes: 'إسناد المدرسين لصفوف',
  assigning_additional_fees_to_students: 'إسناد رسوم إضافية للطلاب ',
  assign_additional_fess: 'اسناد رسوم اضافية',
  approve: 'قبول',
  age_range: 'العمر المحدد',
  add_guardian: 'إضافة ولي أمر',
  allergy_type: 'نوع الحساسية',
  all_fees: 'الرسوم الشاملة',
  base_fees: 'الرسوم الاساسية',
  edit_file: 'تعديل الملف',
  add_new_file: 'إضافة ملف جديد',
  Assigning__teacher__classes: 'إسناد المدرسين للصفوف',
  add_level: 'إضافة صف',
  absent: 'غائب',
  attendance_report: 'تقرير الحضور والغياب',
  add_new_additional_fee: 'إضافة رسوم إضافية',
  all_locations: 'كل الفروع',
  // B
  back_to_login: '< تسجيل الدخول',
  bill: 'قائمة حساب',
  birth_date: 'تاريخ الميلاد',
  birth_place: 'مكان الميلاد',
  black_list: 'القائمه السوداء',
  body: 'المحتوى',
  bondـno: 'رقم السند',
  branch_officer: 'مسئول فرع',
  bus_stand_management: 'رقم الباصات',
  bus_stop_msg: 'هل تريد حدف موقف الباص ؟',
  bus_stop: 'موقف الباص',
  business_name_en: 'اسم الحضانة باللغة الانجليزية',
  business_name_ar: 'اسم الحضانة باللغة العربية',
  brand_information: 'العلامة التجارية',
  brand_name_en: 'اسم العلامة التجارية باللغة الانجليزية',
  brand_name_ar: 'اسم العلامة التجارية باللغة العربية',
  brand_website: 'الموقع الالكتروني',
  brand_social_links: 'روابط وسائل التواصل الاجتماعي',
  brand_tag_line_content_en: 'محتوى سطر العلامة التجارية باللغة الإنجليزي',
  brand_tag_line_content_ar: 'محتوى سطر العلامة التجارية باللغة العربية',
  brand_about_content_en: 'محتوى حول العلامة التجارية باللغة الانجليزية',
  brand_about_content_ar: 'محتوى حول العلامة التجارية باللغة العربية',
  business_information: 'ملعومات الحضانه',
  birth_date_notification: 'اشعار عيد الميلاد',
  // C
  calculate_from: 'حساب الخصم من',
  cancel: 'إلغاء',
  change_date: 'تاريخ التعديل',
  change_language: 'تغير اللغة الى',
  change_password: 'تغير كلمة المرور',
  change_subject: 'تغير المادة',
  child_picker: 'المسؤول عن إستلام الطفل في نهاية الدوام',
  city__town: 'المحافطة / المدينة',
  city: 'المدينة',
  civil_no: 'الرقم المدني',
  classes: 'الصفوف',
  clear: 'Clear-ar',
  clone_all_students: 'نقل الطلاب من السنة السابقة ',
  close_year_desc:
    'في حالة اغلاق السنه الدراسية سيتم أرشفة جميع المعلومات للسنة الحالية',
  communication_information: 'المعلومات التواصل',
  confirm_activating_student: 'تأكيد تفعيل الطالب',
  confirm_deactivating_student: 'تأكيد انسحاب الطالب',
  confirm: 'تاكيد!',
  confirmation: 'تاكيد الطلب',
  contact_info: 'معلومات التواصل',
  contract_type: 'نوع العقد',
  country_code: 'مفتاح الدولة',
  create_account: 'ربط حساب ماي فاتوره',
  create_new_academic_year: 'إنشاء سنة دراسية جديدة',
  create: 'إنشاء',
  customer_email: 'ايميل ولي الأمر',
  customer_mobile: 'رقم جوال ولي الأمر',
  customer_name: 'اسم ولي الأمر',
  choose_the_name_of_the_students: 'اختيار باسم الطلاب',
  contact_person: 'الشخص المفوض',
  first_name: 'الاسم الاول',
  middle_name: 'الاسم الثاني',
  last_name: 'الاسم الاخير',
  contact_number: 'رقم التليفون',
  contact_person_identifications: 'ملفات الهوية للشخص المفوض',
  curriculum: 'تنسيق المنهج الدراسي',
  copy_the_form_link: 'نسخ رابط الاستماره',
  copied: 'تم النسخ',
  // D
  date: 'التاريخ',
  deactivated_students: 'الطلاب المنسحبين',
  deactivated: 'انسحاب الطالب',
  degree: 'التقدير',
  degrees_of_the_year: 'درجات أعمال السنة',
  delete_confirmation: 'تاكيد الحدف !',
  delete_level_with_sections:
    'سوف يتم حذف الصف مع كافة المواد المتعلقه بالصف هدا',
  delete_student: 'حدف الطالب',
  delete_installment: 'هل تريد حدف هدا القسط ؟',
  delete: 'حدف',
  deleted_successfully: 'تم الحدف بنجاح',
  deletion_successful: 'تم الحذف بنجاح',
  detailed_analysis_students: 'التحليل التفصيلي للطلاب',
  detailed_analysis_teachers: 'التحليل التفصيلي للمدرسين',
  detailed_degrees_of_the_year: 'درجات أعمال السنة التفصيلية',
  detailed_quarterly_results: 'النتائج الفصلية التفصيلية',
  detailed_year_work_grades: 'درجات أعمال السنة التفصيلية',
  discount_type: 'نوع الخصم',
  discounts: 'الخصومات',
  add_discount: 'إضافة خصم',
  do_you_want_activated: 'هل تريد تنشيط هذا الطالب:',
  do_you_want_deactivated: ' هل تريد انسحاب هذا الطالب من السنة الدراسية: ',
  do_you_want_to_close_the_current_year: 'هل تريد اغلاق السنه الحالية ؟',
  do_you_want_to_open_the_current_year: 'هل تريد إعادة فتح هذه السنة ؟',
  do_you_want_to_remove_this_fees: 'هل تريد حدف نوع الرسوم ?',
  download_the_final_certificate: 'تنزيل الشهادة النهائية',
  driver_mobile: 'رقم السائق',
  driver_name: 'اسم السائق',
  due_date: 'تاريخ الاستحقاق',
  delete_teacher: 'حدف المدرس',
  deselect_all: 'الغاء اختيار الكل',
  day__days_before: 'يوم / ايام قبل',
  // E
  edit_info: 'تعديل بيانات',
  edit_installment_no: ':تعديل القسط رقم {{number}}',
  edit_payment_no: ':تعديل الرسوم',
  edit_installments_student: 'إعداد الاقساط للطالب',
  edit_role: 'تعديل الصلاحية',
  edit_fee: 'تعديل الرسوم',
  edit: 'تعديل',
  educational_information: 'المعلومات التعليمية',
  educational_level: 'المرحلة الدراسية',
  educational_qualification: 'المؤهل الدراسي',
  effective: 'الفعالين',
  email: 'البريد الالكتروني',
  emergency_number: 'رقم الطوارئ',
  employee_name: 'اسم الموظف',
  end_time: 'نهاية الوقت',
  english: 'انجليزي',
  experience_years: 'سنوات الخبرة',
  extra_columns: ' أعمده اضافية',
  entity_legal_name_en: 'اسم المستفید في البنك باللغة الانجليزية',
  entity_legal_name_ar: 'اسم المستفید في البنك باللغة العربیة',
  entity_country: 'الدولة',
  entity_documents: 'مستندات الكيان',
  expiry_date: 'تاريخ الانتهاء',
  edit_guardian: 'تعديل ولي الامر',
  edit_additional_fee: 'تعديل الرسوم الإضافية',
  // F
  guardian_job_title: 'وظيفة ولي الامر',
  guardian_national_id: 'الرقم المدني لولي الامر',
  father_number: 'رقم الجوال الأب',
  contact_numbers: 'أرقام التواصل',
  fee_type: 'نوع الرسوم',
  fees_payments_msg: 'سوف يتم اعتماد القسط المضاف كا قيمه مدفوعه',
  fees_payments_report: 'الرسوم الإضافية',
  fees_reports: 'الرسوم',
  fees: 'الرسوم',
  filter: ' تصفيه',
  filters: 'تصفيه',
  financial_analysis: 'التحليل المالي',
  fixed_value: 'قيمة ثابتة',
  footer: 'ترويسه اسفل الصفحة',
  forgot_password: 'نسيت كلمة المرور ؟',
  forgot_your_password: 'نسيت كلمة المرور؟',
  from_to: 'من - الى',
  from: 'من',
  full_name: 'الاسم الكامل',
  full_parent_name: 'اسم ولي الامر الكامل',
  // G
  gender: 'الجنس',
  general_information: 'المعلومات العامه',
  generate_template: 'إنشاء النمودج',
  generate: 'إنشاء',
  graduate_year: 'سنه التخرج',
  guardian_information: 'معلومات ولي أمر',
  guardian_mobile: 'رقم جوال ولي الامر',
  guardian_username: 'رقم المستخدم لتطبيق الموبايل',
  // H
  has_entered_hospital: 'هل سبق لطفلك دخول المستشفى ؟',
  has_registered_before: 'هل سبق لكم التسجيل في الحضانة؟',
  header: 'ترويسة أعلى الصفحة',
  health_information: 'المعلومات الصحية',
  health_issues: 'هل يعاني الطفل من المشاكل الصحية التالية ؟',
  health_problems: 'المشاكل الصحية',
  history: 'التسلسل الزمني',
  history_description:
    'يمكنك الاطلاع على تاريخ الطالب والتعديل على البيانات الموجودة',
  home: 'الرئيسية',
  has_allergy: 'هل يعاني الطفل من حساسية ؟',
  // I
  include_it_in_installments: 'تضمينه في أقساط',
  installment_number: 'رقم القسط',
  installments: 'الأقساط',
  instruction_information: 'المعلومات التعليمة',
  integrations: 'خدمات الربط',
  invite: 'دعوة',
  iban: 'ايبان',
  issuing_country: 'بلد الاصدار',
  issuing_date: 'تاريخ الإصدار',
  installments_fees: 'رسوم الاقساط',
  included_in_installments: 'مدرج في الأقساط',
  // J
  job_title_delete_msg: 'هل تريد حدف هدا المسمى الوظيفي ؟',
  job_title: 'المسمى الوظيفي',
  joining_date: 'تاريخ الالتحاق',
  // K
  knet_type: 'نوع ك-نت',
  // L
  language: 'اللغة',
  last_month: 'الشهر السابق',
  last_quarter: 'الربع السابق',
  last_year: 'السنة السابقة',
  late_days: 'الأيام المتأخرة',
  late_installments: 'الاقساط المتأخرة',
  leavels_and_subjects: 'الصفوف والمواد',
  lectures_count: 'عدد الحصص',
  letter: 'النماذج',
  level__section: 'الصف / المرحله',
  level_count: 'عدد الصفوف',
  level: 'الصف',
  levels_and_sections: 'الصفوف والمراحل',
  link: 'الرابط',
  location_delete_msg: 'هل تريد حدف هدا الفرع ؟',
  location_management: 'الفروع',
  location_name: 'اسم الفرع',
  location: 'الفرع',
  log_out: 'تسجيل الخروج',
  login_in: 'تسجيل الدخول',
  login_to_access_the_system: 'قم بتسجيل الدخول للوصول الى النظام',
  license_number: 'رقم الرخصة',
  level_name: 'اسم الصف',
  level_information: 'معلومات الصف',
  level_assigned: 'المواد المسندة',
  // M
  maged_key: 'maged',
  maged_key2: 'sdf',
  maged_key23: 'asd',
  marital_status: 'الحالة الاجتماعية',
  medical_file: 'الملف الطبي',
  mobile_number: 'رقم الجوال الاساسي',
  mobile: 'الجوال',
  modify_the_student_file: 'تعديل ملف الطالب - {{name}}',
  modify_the_teacher_file: 'تعديل ملف المدرس - {{name}}',
  module: 'القسم',
  month_amount: 'الاشتراك الشهري',
  monthly_results: 'النتايج الشهرية',
  months: 'الاشهر',
  mother_job_title: 'وظيفة الأم',
  mother_national: 'الرقم المدني للآم',
  mother_number: 'رقم الجوال للأم',
  merchantـdisplay_name: 'اسم المتجر',
  merchant_info: 'معلومات المتجر',
  mobile__username: 'رقم الجوال / رقم المستخدم لتطبيق الموبايل',
  // N
  name_ar: 'الاسم',
  name_en: 'الاسم بالانجليزي',
  name: 'الاسم',
  national_id: ' الرقم المدني',
  nationality: 'الجنسية',
  new_password_confirmation: 'تاكيد كلمة المرور الجديده',
  new: 'جديد',
  new_password: 'كلمة المرور الجديده',
  no_data: 'لاتوجد بيانات',
  no: 'لا',
  no_additional_installment_found: 'لم يتم العثور على قسط إضافي',
  not_paid: 'غير مدفوع',
  notes: 'ملاحظات',
  notification_by: ' الاشعار بواسطة',
  notification_configurations: 'إعداد الاشعارات',
  number_of_students_level: 'عدد الطلاب في كل مستوى دراسي',
  next: 'التالي',
  no_data_found: 'لم يتم العثور على بيانات',
  // O
  office_number: 'رقم مكتب العمل',
  old_password: 'كلمة المرور القديمه',
  open_year_desc: 'في حالة فتح هذه السنة الدراسية سيتم اغلاق السنوات الأخرى',
  optional_label: '(اختياري)',
  options: 'خيارات اضافية',
  other_fees: 'الرسوم الإضافية',
  other: 'أخرى',
  open_registration_form: 'فتح استمارة التسجيل',
  other_files: 'ملفات اخرى',
  // P
  page_not_authorized: 'لاتوجد لديك صلاحيات لدخول هدا الصفحه',
  page: 'الصفحة',
  paid: 'مدفوع',
  parents_marital_status: 'الحالة الإجتماعية للوالدين',
  password: 'كلمة المرور',
  pay: 'دفع',
  payment_method: 'طريقة الدفع',
  payment_of_installments: 'دفع الرسوم الاضافية',
  payment_status: 'حاله الدفع',
  percent: 'نسبة مئوية',
  period_of_time: 'الفترة الزمنية',
  permissions: 'الصلاحيات',
  personal_information: 'المعلومات الشخصية',
  personal_picture: 'الصوره الشخصية',
  phone: 'الهاتف',
  preparation_of_quotas_times: 'إعداد أوقات الحصص',
  preview: ' معاينه',
  paid_amount: 'المبلغ المدفوع',
  paid_date: 'تاريخ الدفع',
  punch_in: 'وقت الدخول',
  punch_out: 'وقت الخروج',
  payments_fees: 'الرسوم الاضافية',
  parental_consent_to_publish_photos: 'موافقة الوالدين على نشر الصور',
  present: 'حاضر',
  preparation_report: 'تقرير التحضير (QR code)',
  pending_fee_notifications: 'اشعارات الرسوم المعلقة',
  // R
  reason: 'السبب',
  reciept_no_starting: 'رقم السند الابتدائي',
  reciept_no: 'رقم الاقساط',
  recieved_from: 'تم أستلام من :',
  registration_contract: 'استمارة عقد',
  registration_form: 'استمارة تسجيل',
  relationship: 'نوع علاقه ولي الامر بالطالب',
  religion: 'الديانة',
  remaining_amount: 'المبلغ المتبقي',
  report_students_results_detailed: 'تقرير النتائج الفصلية التفصيلية',
  reports_of_the_results: 'تقارير النتايج',
  reports: 'التقارير',
  results_upload_msg: 'سوف يتم رفع الدرجات  وإعتمادها',
  role_delete_msg: 'هل تريد حدف هدا الصلاحية ؟',
  role_type: 'نوع الصلاحية',
  role: 'الصلاحية',
  roles: 'الصلاحيات',
  rpp: 'صف لكل صفحة',
  required: 'الحقل مطلوب',
  reset: 'حذف',
  register_student: 'تسجيل الطالب',
  registration_no_starting: 'رقم القيد الابتدائي',
  // S
  show_details: 'عرض التفاصيل',
  save: 'حفظ',
  school_informations: 'معلومات الحضانة',
  school_logo: 'شعار الحضانة',
  school_name: 'اسم الحضانة',
  school_year: 'السنة الدراسية',
  school_years: 'السنوات الدراسية',
  search: 'البحث',
  section: 'المرحله',
  select: 'أختيار',
  selected: 'مختاره',
  semester_amount: 'الاشتراك الفصلي',
  semester: 'الفصل',
  semesters: 'الفصول',
  settings: 'الاعدادات',
  sms: 'رسائل الجوال',
  social_number: 'الرقم المدني',
  stand_name: 'اسم الموقف',
  start_time: 'بداية الوقت',
  status: 'الحالة',
  student_already_exists_btn: 'طالب موجود مسبقاً',
  student_already_exists_title: 'البحث عند طالب موجود مسبقاً',
  student_fee: 'رسوم الطالب',
  student_fees_report: 'تقارير رسوم الطلاب',
  student_fees: 'رسوم الطلاب',
  student_full_name: 'اسم الطالب الكامل',
  student_full_name_en: 'اسم الطالب الكامل بالانجليزي',
  student_guardian: 'ولي أمر الطالب',
  student_name: 'اسم الطالب',
  student_report: 'تقارير الطلبة',
  student_state: 'حالة الطلاب',
  students_paid_fees: 'رسوم الطلاب المدفوعة',
  students_remaining_fees_report: 'المبالغ المستحقة',
  students: 'الطلاب',
  study_fees_mangaement: 'الرسوم الدراسية',
  study_fees: 'الرسوم الدراسية',
  study_level: 'المستوى الدراسي',
  study_periods_fees_report: 'تقرير الرسوم السنوية',
  study_results: 'النتائج الدراسية',
  subject_management: 'المواد',
  subject: 'المادة',
  subjects: 'المواد',
  submit: 'إرسال',
  subtype: 'نوع الاشتراك',
  suspend_student_msg: 'سوف يتم ايقاف الطالب وحدفه من قائمة الطلاب',
  suspend_student: 'إيقاف الطالب',
  suspend_teacher_msg: 'سوف يتم ايقاف المدرس وحدفه من قائمة المدرس',
  suspend_teacher: 'إيقاف المدرس',
  sync: 'تحديث',
  'selection_by_levels/sections': 'اختيار بالصفوف/الشعب',
  select_all: 'اختيار الكل',
  students_count: 'عدد الطلاب',
  student_files: 'ملفات الطالب',
  send_reminder_Day__Days: 'ارسال تذكير بعد (يوم/ايام)',
  KD_symbol: 'د.ك',
  select_time: 'اختيار الوقت',
  // T
  teacher_management: 'المدرسين',
  teacher: 'المدرس',
  teachers: 'المدرسين',
  template_management: 'إدارة النماذج',
  thats_about: 'وذلك عن',
  the_current_year_has_been_successfully_closed: 'تم اغلاق السنه الحالية بنجاح',
  the_current_year_has_been_successfully_opened: 'تم فتح السنة الدراسية بنجاح',
  this_month: 'هذا الشهر',
  this_quarter: 'هذا الربع',
  this_year: 'هذه السنة',
  time_table_configuration: 'إعداد جدول الحصص',
  time_table: 'جدول الحصص',
  to: 'الى',
  token: 'رمز التكامل',
  total_fees: 'أجمالي الرسوم',
  total_paid_amount: 'أجمالي الرسوم المدفوعة',
  total_remaining_amount: 'إجمالي الرسوم المتبقية',
  total: 'الاجمالي',
  town: 'المحافطة',
  transportation_charges: 'رسوم المواصلات',
  type_your_email: 'البريد الالكتروني',
  teachers_count: 'عدد المدرسين',
  timeline__graphics_settings: 'التسلسل الزمني لاعدادات الرسوم',
  total_additional_installments: 'إجمالي الاقساط الاضافية',
  total_installments: 'إجمالي الاقساط',
  // U
  un_selected: 'غير مختاره',
  university_name: 'اسم الجامعة',
  university_place: 'مكان الجهة التعليمية',
  unsuspend_student: 'تفعيل الطالب',
  unsuspend_teacher: 'تفعيل المدرس',
  upload_file: 'رفع ملف',
  upload_teacher_file: 'رفع ملف للمدرسين',
  upload_the_students: 'Upload the students file',
  uploaded_successfully: 'تم الرفع بنجاح',
  user_id: 'الرقم التعريفي',
  user_name: 'اسم المستخدم',
  user_name__email: 'الرقم التعريفي / البريد الالكتروني',
  user_selected_study_period: 'الفترات الدراسيه',
  users: 'الاعضاء',
  under_registration: 'قيد التسجيل',
  // v
  verified: 'تاكيد',
  // W
  website: 'الموقع الإلكتروني',
  welcome_to_galm_board: 'مرجبا بك في نظام قلم',
  with_transportation: 'مع المواصلات',
  withdrawing: 'المنسحبين',
  day_off: 'يوم إجازة',
  working_hours_configuration: 'إعداد الحضور والانصراف',
  time_configuration: 'إعداد الوقت',
  // Y
  year_amount: 'الاشتراك السنوي',
  year_selected: 'سنة مفعله',
  yearly_results: 'النتايج الفصلية',
  yes: 'نعم',
  // Z
}

export default AR
